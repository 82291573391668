import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/comparepeoratesVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import CombinedForm from "@components/shared/combinedForm";

export default function MainBanner(): ReactElement {
    const { domain } = useDomainContext();
    return domain.categories?.length === 1 ? (
        <div className={styles["main-banner"]}>
            <Form
                classes={{
                    formClassName: styles["form"],
                    stepTitleClassName: styles["step-title"],
                    stepDescriptionClassName: styles["step-description"],
                    formButtonClassName: styles["form-button"],
                    formFieldClassName: styles["form-field"],
                    formStepInnerClassName: styles["steps-inner"],
                    fieldsClassName: {
                        radio: styles["radio-field"],
                        checkbox: styles["checkbox-field"],
                    },
                    fieldsWrapperClassName: styles["fields-wrapper"],
                    tcpaClassName: styles["tcpa"],
                    labelAsTitleClassName: styles["label-as-step-title"],
                    formProgressbarClassName: styles["progress-background"],
                    stepClassName: styles["step"],
                }}
                colors={{
                    primaryColor: colors.defaultButtonColor,
                    progressBar: colors.defaultProgressColor,
                }}
                showProgress={true}
            />
        </div>
    ) : (
        <CombinedForm
            formClasses={{
                stepTitleClassName: styles["step-title"],
                stepDescriptionClassName: styles["step-description"],
                formButtonClassName: styles["form-button"],
                formFieldClassName: styles["form-field"],
                formStepInnerClassName: styles["steps-inner"],
                fieldsClassName: {
                    radio: styles["radio-field"],
                    checkbox: styles["checkbox-field"],
                },
                fieldsWrapperClassName: styles["fields-wrapper"],
                tcpaClassName: styles["tcpa"],
                labelAsTitleClassName: styles["label-as-step-title"],
                formProgressbarClassName: styles["progress-background"],
                stepClassName: styles["step"],
            }}
            mainBannerClassName={styles["main-banner"]}
            primaryColor="#006336"
            colors={{
                primaryColor: colors.defaultButtonColor,
                progressBar: colors.defaultProgressColor,
            }}
            backButtonClassName={styles["back-button"]}
            blockClassName={styles["category-block"]}
            title="HR Solutions to Fuel Your Business Growth"
            description="By streamlining your workflow, you'll have more time to concentrate on expanding your company. Fill out our form now to find the perfect service for you."
            showArrows
            mainTitleClassName="text-2xl leading-10 md:text-4xl md:leading-[50px]"
            descriptionClassName="text-base md:text-lg"
            secondaryTitleClassName="text-base md:text-lg"
            secondaryDescriptionClassName="text-sm md:text-base"
            resetLeadId={true}
        />
    );
}
